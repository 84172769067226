
import { defineComponent, ref, computed, onMounted, reactive, h, nextTick } from 'vue';
import { message as Message, Modal } from 'ant-design-vue'
import moment from "moment"
import { useRouter } from 'vue-router'
import _ from 'lodash'
import { 
  jhManageContractList, 
  jhManageContractProvince, 
  jhManageContractSupervision,
  jhManageContractQuery,
  jhManageContractQueryProvince
} from '@/api/manage'

export default defineComponent({
  name: 'PublicMark',
  mixins: [],
  props: {
  },
  components: {
  },
  setup(props, context) {
    const dataSource = ref<Array<Object>>([]);
    const len = ref<number>(0);
    const text = ref<string>('');
    const title = ref('');
    const loading = ref<boolean>(false);
    function getJhManageContractList(data?: any) {
      if (!data) {
        Message.warning('链接失效')
        return
      };
      loading.value = true
      
      jhManageContractQueryProvince(data).then(res=>{
        if (res.params) {
          dataSource.value = res.params
          text.value = res.text
        }
      }).finally(() => {
        loading.value = false
      })
    }
    const success = (elem) => {
      if (!elem.companyId) {
        Message.warning('没有公司id')
        return;
      }
      jhManageContractQuery({ companyId: elem.companyId }).then(res => {
        if (res) {
          let content = res.name + '，'
          if (res.birthday) {
            content = content + '出生于' + res.birthday + '，'
          }
          if (res.nation) {
            content = content + '民族' + res.nation + '，'
          }
          if (res.nativePlace) {
            content = content + '籍贯' + res.nativePlace + '，'
          }
          
          if (res.school) {
            content = content + '毕业于' + res.school + '，'
          }
          if (res.firstProfession) {
            content = content + res.firstProfession + '专业' + '。'
          }
          if (res.workDate) {
            content = content + '有' +  res.workDate + '年工作经验' + '，'
          }
          if (res.positionalTitles) {
            content = content + '取得了' + res.positionalTitles + '职称，'
          }
          if (res.certificate) {
            content = content + res.certificate + '证书。'
          }
          if (res.industry) {
            content = content + '其团队主营' + res.industry + '行业，'
          }
          if (res.works && res.works.length) {
            let works = ''
            for (let index = 0; index < res.works.length; index++) {
              const element = res.works[index];
              works += element + '；'
              if (index === 2) break;
            }
            content = content + '代表性业绩有' + works + '等项目'
          }
          Modal.success({ 
            title: '负责人简介',
            content: h('div', { }, [
              h('div', content),
            ]),
            centered: true
          });
        } else {
          Message.warning('没有个人信息')
        }
        
      })
      
    };
    const tables = ref(null)
    nextTick(() => {
      window.onresize = _.debounce(function () {
        const $app = tables
        const standardScale = 1080 / 1920
        console.log('translate-------------')
        const docHeight = document.body.clientHeight
        const docWidth = document.body.clientWidth

        if (docWidth < 1680) {
          const currentScale = docHeight / docWidth
          let [scale, translate] = [0, '']
          if (currentScale < standardScale) {
            // 以高度计算
            scale = docHeight / 1080
            const shouleWidth = 1920 * scale
            const offsetWidth = docWidth - shouleWidth
            translate = offsetWidth > 0 ? `translate(${offsetWidth / 2}px, 0)` : ''
          } else {
            // 以宽度计算
            scale = docWidth / 1920
            const shouleHeight = 1080 * scale
            const offsetHeight = docHeight - shouleHeight
            translate = offsetHeight > 0 ? `translate(0, ${offsetHeight / 2}px)` : ''
          }
          
          $app.value.style.cssText = `
          transform: scale(${scale}) ${translate};
          transform-origin: top left;
          min-width: 1920px;
          min-height: 1080px;
        `
        } else {
          $app.value.style.cssText = ''
        }
      })

    })
    const router = useRouter();
    onMounted(() => {
      const data: any = router.currentRoute.value.query
      
      if (data) {
        getJhManageContractList(data)
      }
      const $app = tables
      const standardScale = 1080 / 1920
      const docHeight = document.body.clientHeight
      const docWidth = document.body.clientWidth
      
      if (docWidth < 1680) {
        const currentScale = docHeight / docWidth
        let [scale, translate] = [0, '']
        if (currentScale < standardScale) {
          // 以高度计算
          scale = docHeight / 1080
          const shouleWidth = 1920 * scale
          const offsetWidth = docWidth - shouleWidth
          translate = offsetWidth > 0 ? `translate(${offsetWidth / 2}px, 0)` : ''
        } else {
          // 以宽度计算
          scale = docWidth / 1920
          const shouleHeight = 1080 * scale
          const offsetHeight = docHeight - shouleHeight
          translate = offsetHeight > 0 ? `translate(0, ${offsetHeight / 2}px)` : ''
        }
        
        $app.value.style.cssText = `
        transform: scale(${scale}) ${translate};
        transform-origin: top left;
        min-width: 1920px;
        min-height: 1080px;
      `
      } else {
        $app.value.style.cssText = ''
      }
    })
    return {
      loading,
      text,
      title,
      len, dataSource, success, tables,
      moment,
    };
  },
});
